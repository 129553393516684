.container {
  margin: 5%;
}

.container > div {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.tableContainer {
  width: 100%;
}

.filters {
  display: flex;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.filters > * {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
