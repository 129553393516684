.flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}
